import React from 'react'
import { Button } from 'de-suite-me-ui'

export const PrimaryButton = props => {
  return (
    <Button variant='contained' color='primary' {...props}>
      {props.children}
    </Button>
  )
}

export default PrimaryButton
