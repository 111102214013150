import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import SleepingPanda from 'src/assets/images/illustrations/panda11.png'

export const EmptyState = props => {
  const { t } = useTranslation()

  return (
    <EmptyStateContainer id={props.id}>
      <img
        className='empty-state__image'
        src={SleepingPanda}
        alt='empty=state-panda'
      />
      <div className='empty-state__text'>
        {props.text || t('emptyStates.common')}
      </div>
    </EmptyStateContainer>
  )
}

export default EmptyState

const EmptyStateContainer = styled.div`
  box-sizing: border-box;
  text-align: center;
  padding: 30px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .empty-state__image {
    max-width: 100px;
  }

  .empty-state__text {
    color: ${props => props.theme.palette.text.main};
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
  }
`
