import React from 'react'
import styled from 'styled-components'

import BubbleOrange from 'src/assets/images/vectors/bubble-orange.svg'
import BubblePurple from 'src/assets/images/vectors/bubble-purple.svg'
import BubblePurple2 from 'src/assets/images/vectors/bubble-purple-2.svg'
import BubbleYellow from 'src/assets/images/vectors/bubble-yellow.svg'
import BubbleYellow2 from 'src/assets/images/vectors/bubble-yellow-2.svg'

import CircleTurquoise from 'src/assets/images/vectors/circle-turquise.svg'
import CircleOrange from 'src/assets/images/vectors/circle-orange.svg'

import {
  round1,
  round2,
  round3,
  round4
} from 'src/components/AnimationKeyframes'

export const BubbleBackground = props => {
  const FloatingBubbles = () => (
    <BubbleBackgroundBubbles workspace={props.workspace}>
      {/* LEFT */}

      <img
        className='circle-orange-left'
        src={CircleOrange}
        alt='circle-orange-left'
      />

      <img
        className='bubble-purple-left'
        src={BubblePurple2}
        alt='bubble-purple-left'
      />

      <img
        className='bubble-yellow-left'
        src={BubbleYellow2}
        alt='bubble-yellow-left'
      />

      <img
        className='circle-turquoise-left'
        src={CircleTurquoise}
        alt='circle-turquoise-left'
      />

      {/* RIGHT */}

      <img
        className='bubble-purple-right'
        src={BubblePurple}
        alt='bubble-purple-right'
      />

      <img
        className='bubble-yellow-right'
        src={BubbleYellow}
        alt='bubble-yellow-right'
      />

      <img
        className='circle-turquoise-right'
        src={CircleTurquoise}
        alt='circle-turquoise-right'
      />

      <img
        className='bubble-orange-right'
        src={BubbleOrange}
        alt='bubble-orange-right'
      />
    </BubbleBackgroundBubbles>
  )

  return (
    <BubbleBackgroundWrapper workspace={props.workspace}>
      <BubbleBackgroundContainer workspace={props.workspace} />
      {FloatingBubbles()}
      <BubbleBackgroundContent workspace={props.workspace}>
        {props.children}
      </BubbleBackgroundContent>
    </BubbleBackgroundWrapper>
  )
}

export default BubbleBackground

const BubbleBackgroundWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: ${props => {
    const workspaceValue = '100vh'
    const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeight}))`

    return props.workspace ? workspaceValue : nonWorkspaceValue
  }};
  position: relative;

  ${props => props.theme.breakpointsMedia.mobile} {
    min-height: ${props => {
      const workspaceValue = `calc(100vh - ${props.theme.dimensions.navHeight})`
      const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeightMobile}))`

      return props.workspace ? workspaceValue : nonWorkspaceValue
    }};
  }
`

const BubbleBackgroundContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: ${props => {
    const workspaceValue = '100vh'
    const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeight}))`

    return props.workspace ? workspaceValue : nonWorkspaceValue
  }};
  background-color: ${props =>
    props.theme.palette.common.catskillWhite};

  ${props => props.theme.breakpointsMedia.mobile} {
    min-height: ${props => {
      const workspaceValue = `calc(100vh - ${props.theme.dimensions.navHeight})`
      const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeightMobile}))`

      return props.workspace ? workspaceValue : nonWorkspaceValue
    }};
  }
`

const BubbleBackgroundContent = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => {
    const workspaceValue = '100vh'
    const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeight}))`

    return props.workspace ? workspaceValue : nonWorkspaceValue
  }};
  z-index: 2;
  overflow-y: scroll;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.theme.breakpointsMedia.mobile} {
    overflow-y: unset;
    padding: 10px;
    min-height: ${props => {
      const workspaceValue = `calc(100vh - ${props.theme.dimensions.navHeight})`
      const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeightMobile}))`

      return props.workspace ? workspaceValue : nonWorkspaceValue
    }};
  }
`

const BubbleBackgroundBubbles = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => {
    const workspaceValue = '100vh'
    const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeight}))`

    return props.workspace ? workspaceValue : nonWorkspaceValue
  }};
  z-index: 1;
  overflow-y: scroll;
  padding: 40px 20px;

  ${props => props.theme.breakpointsMedia.mobile} {
    overflow-y: unset;
    min-height: ${props => {
      const workspaceValue = `calc(100vh - ${props.theme.dimensions.navHeight})`
      const nonWorkspaceValue = `calc(100vh - (${props.theme.dimensions.navHeight} + ${props.theme.dimensions.footerHeightMobile}))`

      return props.workspace ? workspaceValue : nonWorkspaceValue
    }};
  }

  .circle-orange-left {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 53px;
    height: 53px;
    animation: ${round1} 6s linear infinite;
  }

  .bubble-purple-left {
    position: absolute;
    top: 35%;
    left: 5%;
    height: 83.5px;
    animation: ${round2} 9s linear infinite;
  }

  .bubble-yellow-left {
    position: absolute;
    top: 67%;
    left: 18%;
    height: 33px;
    animation: ${round3} 8s linear infinite;
  }

  .circle-turquoise-left {
    position: absolute;
    top: 82%;
    left: 10%;
    height: 36px;
    animation: ${round4} 7s linear infinite;
  }

  .bubble-purple-right {
    position: absolute;
    top: 15%;
    right: 7%;
    height: 17px;
    animation: ${round1} 9s linear infinite;
  }

  .bubble-yellow-right {
    position: absolute;
    top: 20%;
    right: 15%;
    height: 47px;
    animation: ${round2} 7s linear infinite;
  }

  .circle-turquoise-right {
    position: absolute;
    top: 55%;
    right: 1%;
    height: 36px;
    animation: ${round3} 8s linear infinite;
  }

  .bubble-orange-right {
    position: absolute;
    top: 75%;
    right: 12%;
    height: 83px;
    animation: ${round4} 6s linear infinite;
  }
`
