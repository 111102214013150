import styled from 'styled-components'

export default styled.div`
  padding: 33px;
  background-color: ${props => props.theme.palette.common.titanWhite};
  box-shadow: ${props => props.theme.shadows.main};
  border-radius: 5px;

  ${props => props.theme.breakpointsMedia.mobile} {
    padding: 15px;
  }
`
